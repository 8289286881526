<template>
  <area-container>
    <area-nav :links="links"></area-nav>
    <area-content>
      <router-view></router-view>
    </area-content>
  </area-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "CompanyLayout",
  computed: {
    ...mapGetters([
      'location', 'user'
    ]),
    links: function() {
      let r = []
      r.push({ route: "company", name: this.$tk("CompanyLayout.Company") })
      r.push({ route: "companyUserList", name: this.$tk("CompanyLayout.Users") })
      r.push({ route: "companyLocationList", name: this.$tk("CompanyLayout.Locations") })
      r.push({ route: "companyExternalLocations", name: this.$tk("CompanyLayout.ExternalLocations") })
      if (this.location.poolingGroupId != "") {
        r.push({ route: "companyAudit", name: this.$tk("CompanyLayout.Counting") })
      }
      if (this.user.isCustomerAdmin && this.user.allowFinancialReports) {
        r.push({ route: "companyEmailArchive", name: this.$tk("CompanyLayout.EmailArchive") })
      }
      return r
    }
  }
}
</script>
